<script>
  import {
    storesForPickupStore as storesForPickup,
    storesForHomeDeliveryStore as storesForHomeDelivery,
    selectedZipCodeStore as selectedZipCode,
    selectedCityStore as selectedCity,
    listModeStore as listMode,
    cityStoresCountStore as cityStoresCount,
    disableHomeDeliveryTabStore as disableHomeDeliveryTab,
  } from '../stores'
  import { selectStore, setOfflineStoreMode } from '../actions'
  import SearchHomeDelivery from './SearchHomeDelivery.svelte'
  import AlternativeListing from './AlternativeListing.svelte'
  import MaxiLogo from './svgs/logos/Maxi.svelte'
  import NaraLogo from './svgs/logos/Nara.svelte'
  import SupermarketLogo from './svgs/logos/Supermarket.svelte'
  import KvantumLogo from './svgs/logos/Kvantum.svelte'
  import CustomLogo, { hasCustomLogo } from './svgs/logos/CustomLogos.svelte'
  import HomeDelivery from './svgs/HomeDelivery.svelte'
  import CollectInStore from './svgs/CollectInStore.svelte'
  import { trackClick } from '../utils'
  import { trackEvent } from '../utils/track'

  let storesForHomeDeliveryCount
  let storesForHomeDeliveryCountText = ''
  let storesForPickupCountText = ''
  let storesForPickupCount
  let disablePickup

  const profileLogos = {
    kvantum: KvantumLogo,
    maxi: MaxiLogo,
    supermarket: SupermarketLogo,
    nara: NaraLogo
  }

  const getStoresList = listMode => {
    const map = {
      pickup: $storesForPickup,
      homeDelivery: $storesForHomeDelivery
    }
    return map[listMode]
  }

  const enterListView = deliveryType => {
    trackClick({ action: 'Välj leveranssätt', label: deliveryType })
    $listMode = deliveryType
  }

  const enterOfflineStoresView = () => {
    setOfflineStoreMode(true)
    trackEvent({
      event: 'genericStoreSelectFindStore',
      linkName: 'Sök offline-butiker'
     })
  }

  storesForHomeDelivery.subscribe(stores => {
    storesForHomeDeliveryCount = stores.length
    $disableHomeDeliveryTab = !stores.length
    storesForHomeDeliveryCountText = stores.length !== 0
      ? `(${stores.length})`
      : ''
  })

  cityStoresCount.subscribe(count => {
    if (!count) {
      storesForPickupCountText = !disablePickup
        ? `(${storesForPickupCount})`
        : ''
    }
  })

  storesForPickup.subscribe(stores => {
    storesForPickupCount = stores.length
    disablePickup = storesForPickupCount === 0
    if ($cityStoresCount) {
      const moreStoresThanSelectedCityStores =
        storesForPickupCount > $cityStoresCount ? '+' : ''
      const cityStoreCount = $cityStoresCount
        ? `${$cityStoresCount}${moreStoresThanSelectedCityStores}`
        : ''
      storesForPickupCountText = !disablePickup
        ? `(${cityStoreCount || storesForPickupCount})`
        : ''
    } else {
      storesForPickupCountText = !disablePickup ? `(${stores.length})` : ''
    }
  })
</script>

<style lang="scss">
  h4,
  h5 {
    font-family: ICARubrik, arial, sans-serif;
    text-align: center;
  }
  h4 {
    margin: 0;
    padding: 0 var(--spacing);
    font-size: 20px;
    font-weight: 300;

    @media screen and (--for-tablet-portrait-up) {
      font-size: 24px;
    }
  }
  h5,
  h6 {
    margin: 0 0 var(--spacing) 0;
    font-weight: 500;
    font-size: 16px;
  }
  h6 {
    margin: var(--spacing);
    font-family: ICATextNy, arial, sans-serif;
    text-align: center;
    font-size: 14px;

    @media screen and (--for-tablet-portrait-up) {
      margin: var(--spacing) calc((var(--spacing) * 2) + 4px);
      text-align: left;
    }
  }
  p {
    text-align: center;

    @media screen and (--for-tablet-portrait-up) {
      text-align: left;
    }
  }
  strong {
    font-weight: 500;
  }
  .store-selector__pickup-home {
    display: flex;
    justify-content: center;
    margin-top: calc(var(--spacing) * 2);
    button {
      color: var(--color-button-text-secondary);
      font-weight: 600;
      line-height: 50px;
      transition: opacity 100ms linear 0ms;
      background-color: var(--color-button-bg-secondary);
      border-radius: var(--spacing);
      margin: 0 4px;
      white-space: nowrap;
      padding: 0 var(--spacing);
      font-size: 14px;

      & :global(svg) {
        display: none;
      }

      @media screen and (min-width: 375px) {
        padding: 0 calc(var(--spacing) * 1.5);
        & :global(svg) {
          display: inline-block;
        }
      }
      @media screen and (--for-tablet-portrait-up) {
        margin: 0 var(--spacing);
        font-size: 16px;
      }
      &:disabled {
        opacity: 0.4;
        cursor: default;
      }
    }
    .selected {
      background-color: var(--color-white);
      color: var(--color-black);
      cursor: default;
      &:hover {
        background-color: var(--color-white);
      }
      & :global(svg) {
        fill: var(--color-black);
      }
    }
  }

  .store-selector__list-container {
    margin-top: -10px;
    background-color: var(--color-background-gray);
    padding-bottom: calc(var(--spacing) * 2);
    border-radius: 0 0 8px 8px;
    position: relative;

    &__list {
      overflow: hidden;
    }
    &__store-not-found {
      display: block;
      line-height: 27px;
      padding: 0;
      color: var(--color-button-bg-primary);
      margin-top: var(--spacing);

      &:hover {
        color: var(--color-button-bg-focus);
      }

      @media screen and (--for-tablet-portrait-up) {
        position: absolute;
        padding: 0;
        top: calc(var(--spacing) * 2);
        right: calc(var(--spacing) * 2);
        margin-top: 0;
      }
    }
    &__header {
      background-color: var(--color-white);
      padding: calc(var(--spacing) * 2) 0;
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }

  .store-item {
    background-color: var(--color-white);
    padding: calc(var(--spacing) * 2) var(--spacing) calc(var(--spacing) * 2)
      calc(var(--spacing) - 4px);
    border-radius: 4px;
    margin: var(--spacing);
    display: flex;
    flex-direction: column;
    border-left: 4px solid transparent;
    box-shadow: 0 0 3px hsla(0, 0%, 74.5%, 0.3);
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        border-left: 4px solid var(--color-ica-red);
      }
    }

    @media screen and (--for-tablet-portrait-up) {
      flex-direction: row;
      margin: var(--spacing) calc(var(--spacing) * 2) 0 calc(var(--spacing) * 2);
    }

    &__column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & :global(svg) {
        height: 40px;
      }
      & :global(img) {
        max-width: calc(150px - (var(--spacing) * 3));
      }
      @media screen and (--for-tablet-portrait-up) {
        align-items: flex-start;
      }
    }
    &__logo {
      margin-bottom: var(--spacing);
      justify-content: center;

      @media screen and (--for-tablet-portrait-up) {
        flex: 0 1 130px;
        margin-bottom: 0;
      }
    }
    &__description {
      flex: 2 0;
      margin-bottom: var(--spacing);
      @media screen and (--for-tablet-portrait-up) {
        margin: 0 var(--spacing) 0 0;
      }
    }
    &__select-store {
      font-family: ICATextNy, sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
      padding: 10px 16px 8px;
      width: 100%;
      max-width: 250px;
      border-radius: 50px;
      background-color: var(--color-button-bg-primary);
      color: #fff;
      cursor: pointer;
      display: inline-block;
      text-align: center;

      @media (hover: hover) {
        &:hover {
          background-color: var(--color-button-bg-focus);
        }
      }
      @media screen and (--for-tablet-portrait-up) {
        width: 120px;
      }
    }
  }
</style>

{#if storesForHomeDeliveryCount || storesForPickupCount}
<div class="store-selector__pickup-home">
  <button
    class="ss-button"
    data-automation-id="store-selector-view-home-delivery"
    class:selected={$listMode === 'homeDelivery'}
    disabled={$disableHomeDeliveryTab}
    on:click={() => enterListView('homeDelivery')}>
    <HomeDelivery />
    Hemleverans {storesForHomeDeliveryCountText}
  </button>
  <button
    class="ss-button"
    data-automation-id="store-selector-view-pickup"
    class:selected={$listMode === 'pickup'}
    disabled={disablePickup}
    on:click={() => enterListView('pickup')}>
    <CollectInStore />
    Hämta i butik {storesForPickupCountText}
  </button>
</div>
{/if}
{#if $listMode}
  {#if $selectedCity && $listMode === 'homeDelivery'}
    <AlternativeListing>
      <SearchHomeDelivery />
    </AlternativeListing>
  {:else}
    <div class="store-selector__list-container">
      <div class="store-selector__list-container__header">
        <h4>
          Snart klar!
          <strong>Välj din butik</strong>
          nedan
        </h4>
        {#if $selectedZipCode}
          <button
            class="ss-button store-selector__list-container__store-not-found"
            on:click={enterOfflineStoresView}>
            Hittar du inte din butik?
          </button>
        {/if}
      </div>
      <ul class="ss-list store-selector__list-container__list">
        {#each getStoresList($listMode) as store, index}
          {#if store.preDivide}
            <h6>Butiker i angränsande områden:</h6>
          {/if}
          <li class="store-item" on:click={() => selectStore(store, index)}>
            <div class="store-item__column store-item__logo">
              {#if hasCustomLogo(store.id)}
                <CustomLogo id={store.id} name={store.name} />
              {:else}
                <svelte:component this={profileLogos[store.profile]} />
              {/if}
            </div>
            <div class="store-item__column store-item__description">
              <h5>{store.name}</h5>
              <p class="ss-paragraph">{store.description}</p>
            </div>
            <div class="store-item__column">
              <button
                data-automation-id={`store-selector-select-store_${store.id}`}
                class="ss-button store-item__select-store">
                Välj butik
              </button>
            </div>
          </li>
          {#if store.postDivide}
            <h6>Butiker i angränsande områden:</h6>
          {/if}
        {/each}
      </ul>
    </div>
  {/if}
{/if}
